import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SimpleHero from '../components/SimpleHero'
import BlogCardListV2 from '../components/BlogCardListV2'
import BlogCardV2 from '../components/BlogCardV2'
import { Helmet } from 'react-helmet'
import Container from '../components/Container'
import Pagination from '../components/Pagination'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'

const Index = ({ data, pageContext }) => {
  const posts = data.allContentfulPost.edges
  const { currentPage } = pageContext
  const isFirstPage = currentPage === 1
  const seo = {
    pageTitle: 'KPC Blog',
    shareImage: {
      ogimg: {
        src: posts[0].node.heroImage.ogimg.src,
        width: posts[0].node.heroImage.ogimg.width,
        height: posts[0].node.heroImage.ogimg.height,
      },
    },
    metaDescription: {
      metaDescription: 'Keeping up with the KPC-dashians',
    },
    metaKeywords: {
      metaKeywords: 'KPC Blog',
    },
  }

  return (
    <Layout>
      {seo && <SEO pagePath={`${pageContext.slug}`} postNode={seo} pageSEO />}
      {!isFirstPage && (
        <Helmet>
          <title>{`${config.siteTitle} - Page ${currentPage}`}</title>
        </Helmet>
      )}
      <Container>
        {/* <SimpleHero headline={headline} pageName={pageName} /> */}
        <SimpleHero
          headline="Keeping up with the KPC-dashians"
          pageName="Blog"
        />
        <BlogCardListV2>
          {posts.map(({ node: post }, i) => {
            return <BlogCardV2 key={post.id} {...post} index={i} />
          })}
        </BlogCardListV2>
        <Pagination context={pageContext} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          id
          slug
          publishDate(formatString: "MMMM, YYYY")
          heroImage {
            title
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_noBase64
            }
            file {
              url
            }
            ogimg: resize(width: 1800) {
              src
              width
              height
            }
          }
          body {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 80)
            }
          }
          topics {
            title
            slug
          }
        }
      }
    }
    contentfulHomePage {
      hero {
        id
      }
    }
  }
`

export default Index
